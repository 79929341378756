import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withEnabledBlockingInitialNavigation } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { provideNgIdleKeepalive } from '@ng-idle/keepalive';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { CustomSerializer } from '@abbadox-monorepo/core-data-access';
import {
  CAREFLOW_API_URL,
  CAREFLOW_AUTH_API_URL,
  CAREFLOW_TOKEN_CONVERTER_API_URL,
} from '@abbadox-monorepo/core-http-client';
import { SIGNLAR_FORMS_HUB_CONFIG } from '@abbadox-monorepo/core-real-time-client';
import {
  careflowTokenInterceptor,
  tenantInterceptor,
  kioskTokenInterceptor,
  careflowConvertedTokenInterceptor,
} from '@abbadox-monorepo/kiosk-auth-data-access';
import {
  KIOSK_CAREFLOW_TOKEN_CONVERTER_CONFIG,
  KIOSK_CAREFLOW_USER_CONFIG,
  KIOSK_API_URL,
  KIOSK_APP_VERSION,
  UPLOADCARE_CONFIG,
} from '@abbadox-monorepo/kiosk-core-http-client';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { appReducers, effects } from './app.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        kioskTokenInterceptor,
        careflowTokenInterceptor,
        careflowConvertedTokenInterceptor,
        tenantInterceptor,
      ]),
    ),
    provideStore(appReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      name: 'Kiosk Standalone App',
    }),
    provideRouterStore({
      serializer: CustomSerializer,
    }),
    provideEffects(effects),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideClientHydration(),
    { provide: KIOSK_API_URL, useValue: environment.kioskApiUrl },
    { provide: CAREFLOW_AUTH_API_URL, useValue: environment.careflowSettings.authApiUrl },
    { provide: KIOSK_CAREFLOW_USER_CONFIG, useValue: environment.careflowSettings.userConfig },
    { provide: CAREFLOW_TOKEN_CONVERTER_API_URL, useValue: environment.careflowSettings.authTokenConverterUrl },
    { provide: KIOSK_CAREFLOW_TOKEN_CONVERTER_CONFIG, useValue: environment.careflowSettings.authTokenConverterConfig },
    { provide: CAREFLOW_API_URL, useValue: environment.careflowSettings.apiUrl },
    { provide: KIOSK_APP_VERSION, useValue: environment.version },
    { provide: UPLOADCARE_CONFIG, useValue: environment.uploadCareConfig },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: '650px' } },
    { provide: SIGNLAR_FORMS_HUB_CONFIG, useValue: environment.signalrHubs.SignalRFormsHub },
    provideNgIdleKeepalive(),
  ],
};

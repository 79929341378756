import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { AppointmentsApiActions, AppointmentsPageActions } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { AuthApiActions } from '@abbadox-monorepo/kiosk-auth-data-access';
import { BillingPageActions } from '@abbadox-monorepo/kiosk-billing-data-access';
import {
  EformsPageActions,
  EformsApiActions,
  selectEformsCompletedStatus,
} from '@abbadox-monorepo/kiosk-eforms-data-access';
import { PatientRecordsApiActions } from '@abbadox-monorepo/kiosk-patient-data-access';
import { KioskFooter, KioskHeaderComponent } from '@abbadox-monorepo/kiosk-ui';
import { FilesStore, UploadUiActions } from '@abbadox-monorepo/kiosk-upload-data-access';
import { selectPageFooter, selectPageHeader, WorkflowUIActions } from '@abbadox-monorepo/kiosk-workflows-data-access';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NgIf, PushPipe, RouterModule, KioskHeaderComponent, KioskFooter],
  template: `
    @if (pageHeaderConfig$ | ngrxPush; as header) {
      <kiosk-header [header]="header"></kiosk-header>
    }

    <main class="relative top-[84px] mx-auto max-w-[50.125rem] px-4 pb-28 pt-4">
      <router-outlet></router-outlet>
    </main>

    <kiosk-footer
      [footer]="pageFooterConfig$ | ngrxPush"
      [nextButtonVisible]="(nextButtonVisible$ | ngrxPush)!"
      [hasImages]="hasImages()"
      (onPrevStepClicked)="navigateToPrevStep($event)"
      (onRestartWorkflowClicked)="navigateToWorkflowsStart()"
      (onNextStepClicked)="navigateToNextStep($event)"
      (onLogoutButtonClicked)="logout()"
    ></kiosk-footer>
  `,
})
export class AppComponent {
  private readonly store = inject(Store);
  private readonly filesStore = inject(FilesStore);

  readonly pageHeaderConfig$ = this.store.select(selectPageHeader);
  readonly pageFooterConfig$ = this.store.select(selectPageFooter);
  readonly nextButtonVisible$ = this.store.select(selectEformsCompletedStatus);

  readonly hasImages = this.filesStore.hasImages;

  navigateToPrevStep(prevStep?: string) {
    this.store.dispatch(WorkflowUIActions.setPrevStep({ prevStep }));
  }

  navigateToWorkflowsStart() {
    this.store.dispatch(WorkflowUIActions.navigateToWorkflowStartFromRestartFooterButtonClick());
  }

  /**
   * Event bus for `nextPage` transitions.
   *
   * If the next step matches a route, actions for API will determine what happens next.
   * i.e. If the next route is from `check-in/authentication` to `check-in/verify-duplicate`, will trigger a form submit.
   *
   * Some actions require an API effect to navigate while other can navigate while side effects handle API calls.
   *
   * @param nextStep the next step in the check-in process
   */
  navigateToNextStep(nextStep?: string) {
    this.store.dispatch(WorkflowUIActions.setNextStep({ nextStep }));

    switch (nextStep) {
      // on patient authorisation widget
      case 'patient-information':
        this.store.dispatch(PatientRecordsApiActions.loadPatientRecordsAttempted());
        break;
      // on patient information widget
      case 'appointment-verification':
        this.store.dispatch(PatientRecordsApiActions.submitPatientRecordCommentsAttempted());
        // load eforms sooner for signalr connection and detect forms completed status
        this.store.dispatch(EformsApiActions.loadEformsUrlAttempted());
        this.store.dispatch(AppointmentsPageActions.navigateToAppointments());
        break;
      // on appointment verification widget
      case 'scan-ids':
        this.store.dispatch(UploadUiActions.navigateToScanningIds());
        break;
      case 'scan-insurance':
        this.store.dispatch(UploadUiActions.navigateToScanningInsurance());
        break;
      case 'patient-forms':
        this.store.dispatch(EformsPageActions.navigateToEforms());
        break;
      case 'billing':
        this.store.dispatch(AppointmentsApiActions.submitAppointmentsCommentAttempted());
        this.store.dispatch(BillingPageActions.navigateToBilling());
        break;
      case 'confirmation':
        // TODO: remove this line when billing page exists
        this.store.dispatch(AppointmentsApiActions.submitAppointmentsCommentAttempted());
        this.store.dispatch(WorkflowUIActions.navigateToConfirmationPage());
        break;
      default:
        break;
    }
  }

  logout() {
    this.store.dispatch(AuthApiActions.logout());
  }
}

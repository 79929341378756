import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { BaseSignalRStoreState, SignalREffects, signalrReducer } from 'ngrx-signalr-core';

import { CORE_UI_STATE_FEATURE_KEY, CoreUiState, coreEffects, coreReducer } from '@abbadox-monorepo/core-data-access';
import {
  ERROR_HANDLER_STATE_FEATURE_KEY,
  ErrorHandlerState,
  errorHandlerReducer,
} from '@abbadox-monorepo/core-error-handler';
import {
  eformsEffects,
  REALTIME_FORMS_FEATURE_KEY,
  realtimeFormsReducer,
  RealtimeFormsState,
} from '@abbadox-monorepo/kiosk-eforms-data-access';

export interface AppState {
  [CORE_UI_STATE_FEATURE_KEY]: CoreUiState;
  [REALTIME_FORMS_FEATURE_KEY]: RealtimeFormsState;
  [ERROR_HANDLER_STATE_FEATURE_KEY]: ErrorHandlerState;
  signalr: BaseSignalRStoreState;
  router: RouterReducerState<any>;
}

export const appReducers: ActionReducerMap<AppState> = {
  [CORE_UI_STATE_FEATURE_KEY]: coreReducer,
  [REALTIME_FORMS_FEATURE_KEY]: realtimeFormsReducer,
  [ERROR_HANDLER_STATE_FEATURE_KEY]: errorHandlerReducer,
  signalr: signalrReducer,
  router: routerReducer,
};

export const effects = [coreEffects, SignalREffects, ...eformsEffects];

import { inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { filter } from 'rxjs/operators';

import { KioskConfigurationsStore } from '@abbadox-monorepo/kiosk-workflows-data-access';

@Injectable({ providedIn: 'root' })
export class PromptUpdateService {
  private readonly kioskConfigStore = inject(KioskConfigurationsStore);

  constructor(swUpdate: SwUpdate) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => this.kioskConfigStore.updateVersion(true));
  }
}

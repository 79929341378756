export const environment = {
  production: true,
  kioskApiUrl: 'https://kiosk.api.delta.abbadox.com/api',
  analyticsUrl: 'https://kiosk.api.delta.abbadox.com/api/analytics',
  careflowSettings: {
    authApiUrl: 'https://platform.abbadox.com/IdsAuthenticationApi.ng.delta/api',
    authTokenConverterUrl: 'https://platform.abbadox.com/TokenConverter/api',
    apiUrl: 'https://platform.abbadox.com/AbbaDoxPlatformApi.ng.delta/api',
    userConfig: {
      login: 'kiosk.demo',
      password: 'demo',
    },
    authTokenConverterConfig: {
      originalApp: 'kiosk',
      forApp: 'abbadox30.ng',
      version: 'v.2',
    },
  },
  signalrHubs: {
    SignalRFormsHub: {
      hubName: 'SignalRFormsHub',
      url: 'https://eformsapi.abbadox.com/FormsWebAPI.Delta/SignalRFormsHub',
      automaticReconnect: true,
    },
  },
  uploadCarePubKey: 'e151cdd5475777cbb6f9',
  uploadCareConfig: {
    pubKey: '18f9bc32f6dece6cf4eb',
    secret: '4f14b4aaeb17ec2ff1d6ca6f217d9ae98536533854b658b1120e8384439015dd',
    expires: 1729203213,
    previewProxy:
      'https://patengapinodejs713ea56de957.azurewebsites.net/api/UploadcarePreviewTokenGenerator?code=rddEOS7pQBG_0MY-f1nsIKQxCQCK1hkjyxPezTLmrWF2AzFuKSCuDw==&',
  },
  version: '1.1.2',
};

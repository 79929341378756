import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Signal, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';

import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filter, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';

import { IdleStore } from '@abbadox-monorepo/core-idle';
import { LocalStorageService } from '@abbadox-monorepo/core-utils';
import { AppointmentsStore } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';
import {
  LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY,
  STARTING_VALUE_TO_EMIT,
  INTERVAL_TO_EMIT_IN_MILLISECONDS,
} from '@abbadox-monorepo/kiosk-core-constants';
import {
  PromptUpdateService,
  LogUpdateService,
  HandleUnrecoverableStateService,
} from '@abbadox-monorepo/kiosk-core-pwa-services';
import {
  RealtimeFormsActions,
  RealtimeFormsStore,
  selectEformsCompletedStatus,
} from '@abbadox-monorepo/kiosk-eforms-data-access';
import { PatientDetailsStore, PatientsSearchStore } from '@abbadox-monorepo/kiosk-patient-data-access';
import { KioskHeader, KioskFooter, FooterNextEvent } from '@abbadox-monorepo/kiosk-ui';
import { UploadWizardStore, FilesStore } from '@abbadox-monorepo/kiosk-upload-data-access';
import {
  KioskConfigurationsStore,
  STEP_ROUTES,
  StepWidget,
  WIDGET_NAMES,
} from '@abbadox-monorepo/kiosk-workflows-data-access';

type WorkflowStepHeaderViewModel = {
  logo: string;
  stepTitle: string;
  stepName: string;
  percentage: number;
};

type WorkflowFooterViewModel = {
  currentStep: string;
  prevStep: string;
  nextStep: string;
  nextButtonVisible: boolean;
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [PushPipe, NgClass, RouterModule, KioskHeader, KioskFooter],
  template: `
    @if (timer$ | ngrxPush) {}
    <kiosk-header
      [logo]="headerViewModel().logo"
      [stepTitle]="headerViewModel().stepTitle"
      [stepName]="headerViewModel().stepName"
      [percentage]="headerViewModel().percentage"
    ></kiosk-header>

    <main
      class="relative top-[84px] mx-auto pb-28 pt-4"
      [ngClass]="{ 'max-w-full': realtimeFormsStep(), 'max-w-[50.125rem] px-4': !realtimeFormsStep() }"
    >
      <router-outlet></router-outlet>
    </main>

    <kiosk-footer
      [currentStep]="footerViewModel().currentStep"
      [prevStep]="footerViewModel().prevStep"
      [nextStep]="footerViewModel().nextStep"
      [nextButtonVisible]="footerViewModel().nextButtonVisible"
      (onPrevStepClicked)="prevStep($event)"
      (onRestartWorkflowClicked)="initWorkflow()"
      (onNextStepClicked)="nextStep($event)"
      (onLogoutButtonClicked)="handleLogoutClick()"
    ></kiosk-footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly promptUpdateService = inject(PromptUpdateService);
  private readonly logUpdateService = inject(LogUpdateService);
  private readonly handleUnrecoverableStateService = inject(HandleUnrecoverableStateService);

  private readonly store = inject(Store);
  readonly eformsCompletedStatus = toSignal(this.store.select(selectEformsCompletedStatus));

  private readonly authStore = inject(AuthStore);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly kioskConfigStore = inject(KioskConfigurationsStore);
  private readonly patientsSearchStore = inject(PatientsSearchStore);
  private readonly patientDetailsStore = inject(PatientDetailsStore);
  private readonly appointmentsStore = inject(AppointmentsStore);
  private readonly uploadWizardStore = inject(UploadWizardStore);
  private readonly filesStore = inject(FilesStore);
  private readonly realtimeFormsStore = inject(RealtimeFormsStore);
  private readonly idleStore = inject(IdleStore);

  readonly hasImages: Signal<boolean> = computed(() => {
    const { hasFront, hasImages } = this.filesStore;
    const { step } = this.uploadWizardStore;

    if (
      (this.kioskConfigStore.uploadIdBackDisabled() && step() === 'front') ||
      (this.kioskConfigStore.uploadInsuranceBackDisabled() && step() === 'front')
    ) {
      return hasFront();
    }

    return hasImages();
  });

  private readonly onDestroy$ = new Subject();
  readonly timer$ = this.localStorageService.getItem<string>(LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY).pipe(
    switchMap((expiresAt) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        takeUntil(this.onDestroy$),
        filter(() => new Date(Number(expiresAt)) < new Date()),
        tap(() => {
          this.authStore.logout();
          this.onDestroy$.next(true);
          this.onDestroy$.complete();
        }),
      ),
    ),
  );

  readonly headerViewModel: Signal<WorkflowStepHeaderViewModel> = computed(() => {
    const logo = this.authStore.logo() ?? '';
    const { stepTitle, stepName, stepsProgress } = this.kioskConfigStore;

    return {
      logo,
      stepTitle: stepTitle(),
      stepName: stepName(),
      percentage: stepsProgress(),
    };
  });

  readonly footerViewModel: Signal<WorkflowFooterViewModel> = computed(() => {
    const { currentStepRoute, nextStep, prevStep } = this.kioskConfigStore;
    const currentStep = currentStepRoute() ?? '';
    const nextButtonVisible = this.isNextButtonVisible(currentStep);

    if (currentStep && /confirmation/.test(currentStep)) {
      return { currentStep, nextStep: '', prevStep: '', nextButtonVisible };
    }

    return { currentStep, nextStep: nextStep(), prevStep: prevStep(), nextButtonVisible };
  });
  readonly realtimeFormsStep = this.kioskConfigStore.realtimeFormsStep;

  ngOnInit(): void {
    if (this.kioskConfigStore.updateAvialable()) {
      document.location.reload();
    }

    this.resetAllStates();
  }

  /**
   * Performs checks on a step before navigting forward if all prereqs pass.
   *
   * Widgets don't depend on steps, and they execute in the order defined by configs.
   * The wizard mvoes onto the next step once all widgets in a step have executed and
   * passed validations.
   *
   * @param nextRoute - route to navigate to next
   */
  async nextStep({ currentRoute, nextRoute }: FooterNextEvent) {
    const stepWidgets = this.kioskConfigStore.stepWidgets;
    if (currentRoute === STEP_ROUTES.AUTHENTICATION) {
      await this.processNextWidget(stepWidgets());
      await this.setupPatient(nextRoute);
    } else if (currentRoute === STEP_ROUTES.PATIENT_INFORMATION) {
      await this.processNextWidget(stepWidgets());
      this.kioskConfigStore.setNextStep(nextRoute);
    } else if (currentRoute === STEP_ROUTES.APPOINTMENT_VERIFICATION) {
      await this.processNextWidget(stepWidgets());
      this.kioskConfigStore.setNextStep(nextRoute);
    } else if (currentRoute === STEP_ROUTES.SCAN_IDS || currentRoute === STEP_ROUTES.SCAN_INSURANCE) {
      this.setupUploadFeature(); // performed at the step level
      this.kioskConfigStore.setNextStep(nextRoute);
      // } else if (currentRoute === STEP_ROUTES.BILLING) {
    } else if (currentRoute === STEP_ROUTES.PATIENT_FORMS) {
      this.kioskConfigStore.setNextStep(nextRoute);
    }
  }

  /**
   * Resets all states and restarts from the workflow selector.
   */
  initWorkflow() {
    this.kioskConfigStore.resetWorkflow();
    this.resetAllStates();
    this.idleStore.stopIdleWatch();
  }

  /**
   * Performs state cleanup before navigating back to step.
   *
   * @param prevRoute - route to navigate back to
   */
  prevStep(prevRoute: string) {
    if (!prevRoute) {
      this.initWorkflow();
    } else if (prevRoute === STEP_ROUTES.AUTHENTICATION || prevRoute === STEP_ROUTES.PATIENT_INFORMATION) {
      this.resetAllStates();
      // patient authentication is always required on this step
      // force re-auth when a patient navigates back to this step
      // via back button or swip action on mobile
      this.kioskConfigStore.setPrevStep(STEP_ROUTES.AUTHENTICATION);
    } else if (prevRoute === STEP_ROUTES.SCAN_IDS || prevRoute === STEP_ROUTES.SCAN_INSURANCE) {
      this.setupUploadFeature(); // performed at the step level
      this.kioskConfigStore.setPrevStep(prevRoute);
    } else if (
      prevRoute === STEP_ROUTES.APPOINTMENT_VERIFICATION ||
      prevRoute === STEP_ROUTES.PATIENT_FORMS ||
      prevRoute === STEP_ROUTES.BILLING ||
      prevRoute === STEP_ROUTES.CONFIRMATION
    ) {
      this.kioskConfigStore.setPrevStep(prevRoute);
    }
  }

  /**
   * Reset all states before clearing the user's  authenticated session.
   */
  handleLogoutClick() {
    this.initWorkflow();
    this.authStore.logout();
  }

  private async processNextWidget(stepWidgets: StepWidget[]) {
    for (const stepWidget of stepWidgets) {
      if (stepWidget.widget.widgetName === WIDGET_NAMES.AUTHENTICATION) {
        const { filters, checkFieldsStatus, searchPatients } = this.patientsSearchStore;

        checkFieldsStatus();
        await searchPatients(filters.params());
      } else if (stepWidget.widget.widgetName === WIDGET_NAMES.PATIENT_DETAILS) {
        const patient = this.patientsSearchStore.selectedEntity();
        await this.patientDetailsStore.savePatientComments(patient);
      } else if (stepWidget.widget.widgetName === WIDGET_NAMES.APPOINTMENT_DETAILS) {
        const patient = this.patientsSearchStore.selectedEntity();
        await this.patientDetailsStore.saveAppointmentComments(patient);
      }
      // else if(stepWidget.widget.widgetName === WIDGET_NAMES.BILLING) {}
    }
  }

  /**
   * Sets up the patient in the patient search step once a patient is found.
   *
   * Validations include:
   * - Verifying appointments for the day
   * - Initializing upload metadata
   * - Starting realtime forms connections
   * - Starting the idle timeout process
   *
   * @param nextRoute - the next step to navigate to
   */
  private async setupPatient(nextRoute: string) {
    const { atCorrectLocation, checkPatientAppointments } = this.appointmentsStore;
    const patient = this.patientsSearchStore.selectedEntity;
    const patientId = this.patientsSearchStore.selectedEntityId;

    if (patientId()) {
      await checkPatientAppointments(patientId());
    }

    if (!this.patientsSearchStore.duplicate() && patientId() && atCorrectLocation()) {
      const patientMrn = patient()?.mrn ?? '';
      await this.uploadWizardStore.verifyUploadedFeatures(patientMrn);
      this.kioskConfigStore.removeCompletedSteps();
      await this.realtimeFormsStore.loadRealtimeForms();
      this.store.dispatch(
        RealtimeFormsActions.loadExtractedCredentials({ eformsToken: this.realtimeFormsStore.eformsToken() }),
      );
      this.idleStore.startIdling();
      this.kioskConfigStore.setNextStep(nextRoute);
    }
  }

  /**
   * Resets the upload feature per step where upload widget features are defined.
   * Each step should reset the feature and not the widget.
   */
  private setupUploadFeature() {
    this.uploadWizardStore.resetUploadWizard();
    this.filesStore.resetFilesState();
  }

  /**
   * Checks next button visibility on validations for specific steps.
   *
   * @param currentStep - the step to verify next button visibility against
   * @returns boolean based on various step validations
   */
  private isNextButtonVisible(currentStep?: string) {
    if (!currentStep) {
      return false;
    }

    if (/scan/g.test(currentStep)) {
      return this.hasImages(); // check for scanned images completed
    } else if (/forms/g.test(currentStep)) {
      return Boolean(this.eformsCompletedStatus());
    } else {
      return !/(billing|confirmation)/g.test(currentStep); // check for restricted pages
    }
  }

  /**
   * Resets all state and stops reatime forms connection.
   */
  private resetAllStates() {
    this.patientsSearchStore.resetPatientSearchState();
    this.patientDetailsStore.resetPatientCommentsState();
    this.appointmentsStore.resetAppointentsState();
    this.filesStore.resetFilesState();
    this.uploadWizardStore.resetUploadWizardState();
    this.realtimeFormsStore.resetRealtimeFormsState();

    this.store.dispatch(RealtimeFormsActions.connectToRealtimeFormsChannelStopped());
  }
}

import { Route } from '@angular/router';

import { authGuard, profileGuard } from '@abbadox-monorepo/kiosk-auth-data-access';
import { NotFoundPage } from '@abbadox-monorepo/kiosk-ui';
import { workflowsGuard } from '@abbadox-monorepo/kiosk-workflows-data-access';

export const appRoutes: Route[] = [
  {
    path: 'home',
    loadComponent: () =>
      import('@abbadox-monorepo/kiosk-workflows-feature-workflows').then((m) => m.WorkflowSelectorPage),
    canActivate: [authGuard, profileGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('@abbadox-monorepo/kiosk-auth-feature-auth').then((m) => m.KioskLoginPage),
  },
  {
    path: ':workflowName',
    loadChildren: () => import('@abbadox-monorepo/kiosk-workflows-feature-workflows').then((m) => m.WORKFLOWS_ROUTES),
    canActivate: [authGuard, profileGuard, workflowsGuard],
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: NotFoundPage },
];
